import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast as superToast } from 'bulma-toast'

const vapidKey = firebase_web_push_key;
const firebaseConfig = JSON.parse(firebase_config);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const notificationContainer = document.querySelector('#messaging-alert');

let refreshToken = false;

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.includes('safari') && !ua.includes('chrome') && !ua.includes('android');
};

const guid = () => {
  const { mimeTypes, userAgent, plugins } = navigator;
  const { height, width, pixelDepth } = screen;
  return `${mimeTypes.length}${userAgent.replace(/\D+/g, '')}${plugins.length}${height || ''}${width || ''}${pixelDepth || ''}`;
};

const fetchToken = async () => {
  try {
    const currentToken = await getToken(messaging, { vapidKey: vapidKey }); // Provide your public VAPID key
   
    if (currentToken) {

      await $.post(url_device_add, {
        token: currentToken,
        refresh: refreshToken,
        company_hash_id: typeof companyHashId !== 'undefined' ? companyHashId : '',
        guid: guid(),
        user_agent: navigator.userAgent,
      });

      if (refreshToken) {
        console.log('FCM Token: refreshed');
      }

      localStorage.setItem('_fcmToken', currentToken);
      console.log('FCM Token: ', currentToken);
    } else {
      console.log('No FCM Token available');
    }
  } catch (err) {
    console.error('Error retrieving token: ', err);

    if (err.code === 'messaging/token-unsubscribe-failed') {
      console.warn('FCM token unsubscribe failed, clearing local token.');
      // Remove the token from local storage and retry
      localStorage.removeItem('_fcmToken');
      // await fetchToken(); // Retry fetching the token after clearing
    } else if (err.code === 'messaging/permission-blocked') {
      alert('Notifications are blocked. Please enable them in your browser settings.');
    }

  }
};


window.initWorker = async () => {
  const URL = `/firebase-messaging-sw.js`;

  if ('serviceWorker' in navigator) {

    // navigator.serviceWorker.addEventListener('controllerchange', () => {
    //   console.log('Service Worker controller changed, reloading page...');
    //   window.location.reload();
    // });
    
    try {
      const registration = await navigator.serviceWorker.getRegistration(URL);

      if (registration) {
        console.log('Service Worker registered:', registration);
        registration.update(); // Force update check
      } else {
        const reg = await navigator.serviceWorker.register(URL, { scope: '/' });
        console.log('Service Worker registered:', reg);

        // Listen for updates
        reg.onupdatefound = () => {
          const newWorker = reg.installing;
          console.log('New Service Worker found:', newWorker);

          newWorker.onstatechange = () => {
            if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
              console.log('New Service Worker installed.');
            }
          };
        };
      }
    } catch (err) {
      console.error('Service worker registration failed:', err);
    }
  }
};

onMessage(messaging, (payload) => {
  console.log('Received foreground payload:', payload);
});



window.requestPermissionStatus = async () => {
  let permission = Notification.permission;

  switch(permission) {
    case 'granted':

      if(localStorage.getItem('permissionStatus') != permission) {
        localStorage.setItem('notification-permissions', permission);
        window.dispatchEvent(new Event("permissionStatus"));
      }

      await fetchToken();
    break;
    
    case 'denied':
      console.error('Notifications are denied by the user.');
      localStorage.setItem('notification-permissions', 'denied');

      if(localStorage.getItem('permissionStatus') != permission) {
        localStorage.setItem('notification-permissions', permission);
        window.dispatchEvent(new Event("permissionStatus"));
      }

    break;

    default:
      console.log('Notifications permission is in default state, please accept to receive notifications.');
      localStorage.setItem('notification-permissions', 'default');

      if(localStorage.getItem('permissionStatus') != permission) {
        localStorage.setItem('notification-permissions', permission);
        window.dispatchEvent(new Event("permissionStatus"));
      }
    break;
  } 
}


// Initialize worker on page load
document.addEventListener('DOMContentLoaded', () => {
  if (typeof window.initWorker === 'function') {
    window.initWorker();
  }

  if ('permissions' in navigator) {
    navigator.permissions.query({ name: 'notifications' }).then(function (notificationPerm) {
      notificationPerm.onchange = function () {
        console.log("User decided to change his seettings. New permission: " + notificationPerm.state);
         window.requestPermissionStatus();
      };
    });
  }

  if(!isSafari()) {
    const permission =  Notification.requestPermission();
  }
  

});
