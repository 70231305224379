import "./lib/firebase";

const showClientNotify = (res) => {
    const msg = `${res.title} - ${res.body}`;


    const notifyBell = document.querySelector('#notify-bell');

    if (notifyBell) {
        notifyBell.classList.remove('fa-bell-o');
        notifyBell.classList.add('fa-bell', 'faa-tada', 'animated', 'text-info');
        notifyBell.setAttribute('data-original-title', msg);
    }

    // Ensure the audio file path is correct and accessible
    const audio = new Audio('/assets/lobibox/sounds/coin.ogg');
    audio.volume = 0.5;

    // Try to play the audio and catch any errors
    audio.play().catch(error => {
        console.error('Failed to play sound:', error);
    });

};

// Set up the BroadcastChannel listener
const channel = new BroadcastChannel('sw-messages');

channel.addEventListener('message', event => {
    if (event.data) {
        showClientNotify(event.data);
    } else {
        console.warn('Received empty message from BroadcastChannel');
    }
});
